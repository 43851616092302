import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoadingService } from '@issuerservices/design-system';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AdminIncidentApiService } from 'src/app/modules/admin/services/incident.service';
import { AdminMarketApiService } from 'src/app/modules/admin/services/market.service';
import { AdminPeopleApiService } from 'src/app/modules/admin/services/people.service';
import { AdminTemplateApiService } from 'src/app/modules/admin/services/template.service';
import { AdminWamApiService } from 'src/app/modules/admin/services/wam.service';
import { pageRequest } from 'src/app/utils/page-request';
import {
  addIncidentsRedirect,
  addMarketRedirect,
  addTemplateRedirect,
  addUserRedirect,
  addWamRedirect,
  adminIncident,
  adminIncidentFailure,
  adminIncidentSuccess,
  adminIncidents,
  adminIncidentsFailure,
  adminIncidentsFilter,
  adminIncidentsSuccess,
  adminIncidentsTemplates,
  adminIncidentsTemplatesFailure,
  adminIncidentsTemplatesSuccess,
  adminMarket,
  adminMarketFailure,
  adminMarketSuccess,
  adminMarkets,
  adminMarketsFailure,
  adminMarketsSuccess,
  adminPeople,
  adminPeopleFailure,
  adminPeopleFilter,
  adminPeopleSuccess,
  adminTemplate,
  adminTemplateFailure,
  adminTemplateSuccess,
  adminTemplates,
  adminTemplatesFailure,
  adminTemplatesSuccess,
  adminWam,
  adminWamFailure,
  adminWamSuccess,
  adminWams,
  adminWamsFailure,
  adminWamsSuccess,
  archiveIncidents,
  archiveIncidentsFailure,
  archiveIncidentsSuccess,
  cancelIncidentsRedirect,
  cancelMarketRedirect,
  cancelTemplateRedirect,
  cancelWamRedirect,
  getUser,
  getUserFailure,
  getUserSuccess,
  initAdminIncident,
  initAdminIncidents,
  initAdminPeople,
  initAdminTemplate,
  initAdminWam,
  removeIncidents,
  removeIncidentsFailure,
  removeIncidentsSuccess,
  removeMarket,
  removeMarketFailure,
  removeMarketSuccess,
  removeSubIncidents,
  removeSubIncidentsFailure,
  removeSubIncidentsSuccess,
  removeTemplate,
  removeTemplateFailure,
  removeTemplateSuccess,
  removeWam,
  removeWamFailure,
  removeWamSuccess,
  saveIncidents,
  saveIncidentsFailure,
  saveIncidentsSuccess,
  saveMarket,
  saveMarketFailure,
  saveMarketSuccess,
  saveSubIncidents,
  saveSubIncidentsFailure,
  saveSubIncidentsSuccess,
  saveTemplate,
  saveTemplateFailure,
  saveTemplateSuccess,
  saveWam,
  saveWamFailure,
  saveWamSuccess,
  updateIncidents,
  updateIncidentsFailure,
  updateIncidentsRedirect,
  updateIncidentsSuccess,
  updateMarket,
  updateMarketFailure,
  updateMarketRedirect,
  updateMarketSuccess,
  updateStatusPeople,
  updateStatusPeopleFailure,
  updateStatusPeopleSuccess,
  updateTemplate,
  updateTemplateFailure,
  updateTemplateRedirect,
  updateTemplateSuccess,
  updateUserRedirect,
  updateWam,
  updateWamFailure,
  updateWamRedirect,
  updateWamSuccess,
} from '../actions/admin.actions';
import { State } from '../reducers/admin.reducer';
import { selectAdminIncidentsRequest, selectAdminPeopleRequest, selectAdminTemplatesRequest } from '../selectors/admin.selectors';

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private adminIncidentApiService: AdminIncidentApiService,
    private adminMarketService: AdminMarketApiService,
    private adminTemplateApiService: AdminTemplateApiService,
    private adminPeopleApiService: AdminPeopleApiService,
    private adminWamApiService: AdminWamApiService
  ) {}

  loadFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          saveMarketFailure,
          adminMarketFailure,
          removeMarketFailure,
          updateMarketFailure,
          removeIncidentsFailure,
          archiveIncidentsFailure,
          saveIncidentsFailure,
          updateIncidentsFailure,
          removeTemplateFailure,
          saveTemplateFailure,
          updateTemplateFailure,
          removeWamFailure,
          saveWamFailure,
          updateWamFailure
        ),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  doneSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          saveMarketSuccess,
          removeMarketSuccess,
          updateMarketSuccess,
          saveTemplateSuccess,
          updateTemplateSuccess,
          removeTemplateSuccess,
          saveWamSuccess,
          updateWamSuccess,
          removeWamSuccess,
          updateIncidentsSuccess,
          saveIncidentsSuccess
        ),
        tap((action) =>
          this.snackBar.open(action.message, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'success-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  removeMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMarket),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminMarketService.removeMarket(action.id).pipe(
          map((response) =>
            response.result && response.message === 'Market Deleted successfully'
              ? removeMarketSuccess({ message: `${response.result.marketTitle} market has been deleted` })
              : removeMarketFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              removeMarketFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  addMarketRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addMarketRedirect),
        tap(() => {
          this.router.navigate(['admin/market']);
        })
      ),
    { dispatch: false }
  );

  cancelMarketRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelMarketRedirect, saveMarketSuccess, updateMarketSuccess, removeMarketSuccess),
        tap(() => {
          this.router.navigate(['admin/markets']);
        })
      ),
    { dispatch: false }
  );

  updateMarketRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMarketRedirect),
        tap((action) => {
          this.router.navigate([`admin/market/${action.id}`]);
        })
      ),
    { dispatch: false }
  );

  adminMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminMarket),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminMarketService.getMarket(action.id).pipe(
          map((response) =>
            response.result && (response.message === 'Market fetched successfully' || response.message === 'Markets fetched successfully')
              ? adminMarketSuccess({ response: response.result })
              : adminMarketFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminMarketFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  updateMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMarket),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminMarketService.updateMarket(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Market updated successfully'
              ? updateMarketSuccess({ message: `${response.result.marketTitle} market updated successfully.` })
              : updateMarketFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              updateMarketFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  saveMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMarket),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminMarketService.createMarket(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Market created successfully'
              ? saveMarketSuccess({ message: `${response.result.marketTitle} market saved successfully.` })
              : saveMarketFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              saveMarketFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  removeIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeIncidents),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.removeIncident(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Incident Deleted successfully'
              ? removeIncidentsSuccess({ response: response.result })
              : removeIncidentsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              removeIncidentsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  addIncidentsRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addIncidentsRedirect),
        tap(() => {
          this.router.navigate(['admin/incident']);
        })
      ),
    { dispatch: false }
  );

  cancelIncidentsRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelIncidentsRedirect, saveIncidentsSuccess, updateIncidentsSuccess),
        tap(() => {
          this.router.navigate(['admin/incidents']);
        })
      ),
    { dispatch: false }
  );

  updateIncidentsRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateIncidentsRedirect),
        tap((action) => {
          this.router.navigate([`admin/incident/${action.request.id}/${action.request.market}`]);
        })
      ),
    { dispatch: false }
  );

  updateUserRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserRedirect),
        tap((action) => {
          this.router.navigate([`admin/people/user/${action.email}`]);
        })
      ),
    { dispatch: false }
  );

  addUserRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addUserRedirect),
        tap((action) => {
          this.router.navigate([`admin/people/newuser`]);
        })
      ),
    { dispatch: false }
  );

  initAdminIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAdminIncidents),
      switchMap(() => [adminIncidents({}), adminMarkets()])
    )
  );

  initAdminIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAdminIncident),
      switchMap((action) => [
        adminIncident({ request: action.request }),
        adminMarkets(),
        adminIncidentsTemplates({ market: action.request.market }),
      ])
    )
  );

  adminIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminIncidents),
      withLatestFrom(this.store.select(selectAdminIncidentsRequest)),
      switchMap(([action, request]) =>
        this.adminIncidentApiService
          .allIncidents({
            ...pageRequest(
              request.lastEvaluatedKey,
              request.lastEvaluatedIndex,
              action?.pageSize ?? request.pageSize,
              request.currentPageNumber,
              action?.page ?? request.currentPageNumber[1],
              (action?.pageSize && action?.pageSize !== request.pageSize) || request.isFilterChanged
            ),
            filters: request.filters,
          })
          .pipe(
            map((response) =>
              response.result && response.message === 'Incidents fetched successfully'
                ? adminIncidentsSuccess({ response: response.result })
                : adminIncidentsFailure({ error: response.message })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                adminIncidentsFailure({
                  error:
                    error.error && error.error.message
                      ? `${error.error.message} ${error.error.result}`
                      : 'Unknown Error. Please try again later',
                })
              )
            )
          )
      )
    )
  );
  initPeople$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAdminPeople),
      switchMap(() => [adminPeople({}), adminMarkets()])
    )
  );

  loadPeople$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminPeople),
      withLatestFrom(this.store.select(selectAdminPeopleRequest)),
      switchMap(([action, request]) =>
        this.adminPeopleApiService
          .allPeople({
            ...pageRequest(
              request.lastEvaluatedKey,
              request.lastEvaluatedIndex,
              action?.pageSize ?? request.pageSize,
              request.currentPageNumber,
              action?.page ?? request.currentPageNumber[1],
              (action?.pageSize && action?.pageSize !== request.pageSize) || request.isFilterChanged
            ),
            filters: request.filters,
          })
          .pipe(
            map((response) =>
              response.result && response.message === 'User records fetched successfully'
                ? adminPeopleSuccess({ response: response.result })
                : adminPeopleFailure({ error: response.message })
            ),
            catchError((error) =>
              of(
                adminPeopleFailure({
                  error:
                    error.error && error.error.message
                      ? `${error.error.message} ${error.error.result}`
                      : 'Unknown Error. Please try again later',
                })
              )
            )
          )
      )
    )
  );

  adminPeopleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminPeopleFilter),
      switchMap(() => [adminPeople({})])
    )
  );

  updateStatusPeople$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStatusPeople),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminPeopleApiService.updateStatus(action.request).pipe(
          map((response) =>
            response.result && response.message === 'User status bulk operation processed successfully'
              ? updateStatusPeopleSuccess({ response: response.result })
              : updateStatusPeopleFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              updateStatusPeopleFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  updateStatusPeopleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateStatusPeopleFailure),
        tap((action) => console.error(action.error)),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        ),
        switchMap(() => [adminPeople({})])
      ),
    { dispatch: false }
  );

  updateStatusPeopleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStatusPeopleSuccess),
      tap((action) =>
        this.snackBar.open('The Status has been successfully updated', 'X', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: 'success-snackbar',
          duration: 5000,
        })
      ),
      switchMap(() => [adminPeople({})])
    )
  );

  adminIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminIncident),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.getIncident(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Incident fetched successfully'
              ? adminIncidentSuccess({ response: response.result })
              : adminIncidentFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminIncidentFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminPeopleApiService.getUser(action.email).pipe(
          map((response) =>
            response.result && response.message === 'User details fetched successfully'
              ? getUserSuccess({ response: response.result })
              : getUserFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              getUserFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  adminIncidentsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminIncidentsFilter),
      switchMap(() => [adminIncidents({})])
    )
  );

  adminIncidentsMarkets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminMarkets),
      tap(() => this.loadingService.show()),
      switchMap(() =>
        this.adminMarketService.markets().pipe(
          map((response) =>
            response.result && response.message === 'Markets fetched successfully'
              ? adminMarketsSuccess({ response: response.result })
              : adminMarketsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminMarketsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          adminMarketSuccess,
          adminMarketFailure,
          saveMarketSuccess,
          saveMarketFailure,
          removeMarketSuccess,
          removeMarketFailure,
          updateMarketSuccess,
          updateMarketFailure,
          adminMarketsSuccess,
          adminMarketsFailure,
          adminIncidentsTemplatesSuccess,
          adminIncidentsTemplatesFailure,
          archiveIncidentsSuccess,
          archiveIncidentsFailure,
          updateStatusPeopleSuccess,
          updateStatusPeopleFailure,
          removeIncidentsSuccess,
          removeIncidentsFailure,
          saveIncidentsSuccess,
          saveIncidentsFailure,
          updateIncidentsSuccess,
          updateIncidentsFailure,
          saveTemplateSuccess,
          saveTemplateFailure,
          removeTemplateSuccess,
          removeTemplateFailure,
          updateTemplateSuccess,
          updateTemplateFailure,
          adminIncidentSuccess,
          adminIncidentFailure,
          saveWamSuccess,
          saveWamFailure,
          updateWamSuccess,
          updateWamFailure,
          removeWamSuccess,
          removeWamFailure,
          getUserSuccess
        ),
        tap(() => this.loadingService.hide())
      ),
    { dispatch: false }
  );

  adminIncidentsTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminIncidentsTemplates),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminTemplateApiService.templateByMarket(action.market).pipe(
          map((response) =>
            response.result && response.message === 'Templates fetched successfully'
              ? adminIncidentsTemplatesSuccess({ response: response.result })
              : adminIncidentsTemplatesFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminIncidentsTemplatesFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  archiveIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archiveIncidents),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.archiveIncident(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Successfully Archived the Incident'
              ? archiveIncidentsSuccess({ response: response.result })
              : archiveIncidentsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              archiveIncidentsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  saveIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveIncidents),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.createIncident(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Incident created successfully'
              ? saveIncidentsSuccess({ message: `${response.result.incidentTitle} incident saved successfully.` })
              : saveIncidentsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              saveIncidentsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  updateIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIncidents),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.updateIncident(action.request).pipe(
          switchMap((response) => {
            const successActions = [];
            if (action.addSubIncidents.length > 0) {
              successActions.push(saveSubIncidents({ request: action.addSubIncidents }));
            }
            if (action.deleteSubIncidents.length > 0) {
              successActions.push(removeSubIncidents({ request: action.deleteSubIncidents }));
            }
            return response.result && response.message === 'Incident updated successfully'
              ? [updateIncidentsSuccess({ message: `${response.result.incidentTitle} incident saved successfully.` }), ...successActions]
              : [updateIncidentsFailure({ error: response.message })];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              updateIncidentsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.message} ${error.error.result}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  removeSubIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSubIncidents),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.removeSubIncident(action.request).pipe(
          map((response) =>
            response.length > 0 && response.every((el) => el.message === 'Deleted the Sub-Incident successfully')
              ? removeSubIncidentsSuccess({ message: 'Deleted the Sub-Incident successfully' })
              : removeSubIncidentsFailure({ error: response.find((el) => el.message !== 'Deleted the Sub-Incident successfully').message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              removeSubIncidentsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.message} ${error.error.result}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  saveSubIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSubIncidents),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminIncidentApiService.createSubIncident(action.request).pipe(
          map((response) =>
            response.length > 0 && response.every((el) => el.message === 'Sub-Incident added successfully')
              ? saveSubIncidentsSuccess()
              : saveSubIncidentsFailure({ error: response.find((el) => el.message !== 'Sub-Incident added successfully').message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              saveSubIncidentsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.message} ${error.error.result}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  removeTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTemplate),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminTemplateApiService.removeTemplate(action.id).pipe(
          map((response) =>
            response.result && response.message === 'Template Deleted successfully'
              ? removeTemplateSuccess({ message: `${response.result.templateTitle} template has been deleted` })
              : removeTemplateFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              removeTemplateFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  addTemplateRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addTemplateRedirect),
        tap(() => {
          this.router.navigate(['admin/template']);
        })
      ),
    { dispatch: false }
  );

  cancelTemplateRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelTemplateRedirect, saveTemplateSuccess, updateTemplateSuccess, removeTemplateSuccess),
        tap(() => {
          this.router.navigate(['admin/templates']);
        })
      ),
    { dispatch: false }
  );

  updateTemplateRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTemplateRedirect),
        tap((action) => {
          this.router.navigate([`admin/template/${action.id}`]);
        })
      ),
    { dispatch: false }
  );

  initAdminTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAdminTemplate),
      switchMap((action) => [adminTemplate({ id: action.id }), adminMarkets()])
    )
  );

  adminTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminTemplates),
      withLatestFrom(this.store.select(selectAdminTemplatesRequest)),
      switchMap(([action, request]) =>
        this.adminTemplateApiService
          .allTemplates({
            ...pageRequest(
              request.lastEvaluatedKey,
              request.lastEvaluatedIndex,
              action?.pageSize ?? request.pageSize,
              request.currentPageNumber,
              action?.page ?? request.currentPageNumber[1],
              action?.pageSize && action?.pageSize !== request.pageSize
            ),
          })
          .pipe(
            map((response) =>
              response.result && response.message === 'Templates fetched successfully'
                ? adminTemplatesSuccess({ response: response.result })
                : adminTemplatesFailure({ error: response.message })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                adminTemplatesFailure({
                  error:
                    error.error && error.error.message
                      ? `${error.error.result ? error.error.result : error.error.message}`
                      : 'Unknown Error. Please try again later',
                })
              )
            )
          )
      )
    )
  );

  adminTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminTemplate),
      switchMap((action) =>
        this.adminTemplateApiService.getTemplate(action.id).pipe(
          map((response) =>
            response.result &&
            (response.message === 'Templates fetched successfully' || response.message === 'Template fetched successfully')
              ? adminTemplateSuccess({ response: response.result })
              : adminTemplateFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminTemplateFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  updateTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTemplate),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminTemplateApiService.updateTemplate(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Template updated successfully'
              ? updateTemplateSuccess({ message: `${response.result.templateTitle} template updated successfully.` })
              : updateTemplateFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              updateTemplateFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  saveTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveTemplate),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminTemplateApiService.createTemplate(action.request).pipe(
          map((response) =>
            response.result && response.message === 'Template created successfully'
              ? saveTemplateSuccess({ message: `${response.result.templateTitle} template saved successfully.` })
              : saveTemplateFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              saveTemplateFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  removeWam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeWam),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminWamApiService.removeWam(action.id).pipe(
          map((response) =>
            response.result && response.message === 'WAM Activity deleted successfully'
              ? removeWamSuccess({ message: 'WAM Activity deleted successfully' })
              : removeWamFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              removeWamFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  addWamRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addWamRedirect),
        tap(() => {
          this.router.navigate(['admin/wam']);
        })
      ),
    { dispatch: false }
  );

  cancelWamRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelWamRedirect, saveWamSuccess, updateWamSuccess, removeWamSuccess),
        tap(() => {
          this.router.navigate(['admin/wams']);
        })
      ),
    { dispatch: false }
  );

  updateWamRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateWamRedirect),
        tap((action) => {
          this.router.navigate([`admin/wam/${action.id}`]);
        })
      ),
    { dispatch: false }
  );

  initAdminWam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAdminWam),
      switchMap((action) => [adminWam({ id: action.id }), adminMarkets()])
    )
  );

  adminWams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminWams),
      switchMap(() =>
        this.adminWamApiService.allWams().pipe(
          map((response) =>
            response.result && response.message === 'WAM records fetched successfully'
              ? adminWamsSuccess({ response: response.result })
              : adminWamsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminWamsFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  adminWam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminWam),
      switchMap((action) =>
        this.adminWamApiService.getWam(action.id).pipe(
          map((response) =>
            response.result && response.message === 'WAM activity fetched successfully'
              ? adminWamSuccess({ response: response.result })
              : adminWamFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              adminWamFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result ? error.error.result : error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  updateWam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWam),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminWamApiService.updateWam(action.request).pipe(
          map((response) =>
            response.result && response.message === 'WAM activity updated successfully'
              ? updateWamSuccess({ message: 'WAM activity updated successfully.' })
              : updateWamFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              updateWamFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result} - ${error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );

  saveWam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveWam),
      tap(() => this.loadingService.show()),
      switchMap((action) =>
        this.adminWamApiService.createWam(action.request).pipe(
          map((response) =>
            response.result && response.message === 'WAM activity created successfully'
              ? saveWamSuccess({ message: 'WAM activity saved successfully.' })
              : saveWamFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              saveWamFailure({
                error:
                  error.error && error.error.message
                    ? `${error.error.result} - ${error.error.message}`
                    : 'Unknown Error. Please try again later',
              })
            )
          )
        )
      )
    )
  );
}
