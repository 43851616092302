import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from '@issuerservices/design-system';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { ArchivedIncidentsComponent } from './modules/archived-incidents/components/archived-incidents.component';
import { StatusDetailsComponent } from './modules/home/components/status-details/status-details.component';
import { StatusComponent } from './modules/home/components/status/status.component';
import { DataProtectionRequirementsComponent } from './modules/static-page/data-protection-requirements/data-protection-requirements.component';
import { NotFoundComponent } from './modules/static-page/not-found/not-found.component';
import { TermsConditionsComponent } from './modules/static-page/terms-conditions/terms-conditions.component';
import { UserAccountDeleteComponent } from './modules/user/components/user-account-delete/user-account-delete.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    data: {
      layout: 'common',
      title: 'LSEG live system status  | LSEG system status',
      header: {
        title: 'LSEG live system status',
        subtitle: '',
        bgClass: 'admin',
      },
    },
    children: [
      {
        path: '',
        component: StatusComponent,
        title: 'LSEG live system status | LSEG system status',
      },
      {
        path: 'callback',
        component: OktaCallbackComponent,
        title: 'LSEG live system status  | LSEG system status',
      },
      {
        path: 'incident/:id/:market',
        component: StatusDetailsComponent,
        title: 'Incident Details  | LSEG system status',
        data: {
          header: {
            title: 'Incident Details',
          },
        },
      },
      {
        path: 'archive',
        component: ArchivedIncidentsComponent,
        title: 'Archive  | LSEG system status',
        data: {
          header: {
            title: 'Archive',
            bgClass: 'admin',
          },
        },
      },
      {
        path: 'contact-us',
        component: StatusComponent,
        title: 'Contact us  | LSEG system status',
        data: {
          header: {
            title: 'Contact us',
            subtitle: 'Phone: +44 (0)20 7797 1500\nEmail: support@lseg.com',
            bgClass: 'admin',
          },
        },
      },
      {
        path: 'wam',
        loadChildren: () => import('./modules/wam/wam.routes'),
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.routes'),
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.routes'),
      },
      {
        path: 'data-protection-requirements',
        component: DataProtectionRequirementsComponent,
        title: 'Data Protection requirements | LSEG system status',
        data: {
          header: {
            title: 'Data Protection requirements',
            subtitle: '',
            bgClass: 'default',
          },
        },
      },
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent,
        title: 'Terms & Conditions | LSEG system status',
        data: {
          header: {
            title: 'Terms & Conditions',
            subtitle: '',
            bgClass: 'default',
          },
        },
      },
      {
        path: 'cancel/user/account',
        component: UserAccountDeleteComponent,
        title: 'Account Deletion | LSEG system status',
        data: {
          header: {
            title: 'Account Deletion',
            subtitle: '',
            bgClass: 'admin',
          },
        },
      },
      {
        path: '404',
        component: NotFoundComponent,
        title: 'Page not found | LSEG system status',
        data: {
          header: {
            title: 'Error 404',
            subtitle: '',
            bgClass: 'admin',
          },
        },
      },
      { path: '**', redirectTo: '/404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), MatSnackBarModule, HttpClientModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
