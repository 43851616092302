<div class="section-grid-container" *ngIf="incident$ | async as incident; else spinner">
  <div class="single-content-wrapper">
    <h2>{{ incident.publishedOn }} - {{ incident.incidentTitle }} for {{ incident.marketTitle }}</h2>
    <div *ngIf="incident.incidentDescription">{{ incident.incidentDescription }}</div>
    <div *ngIf="incident.subIncidentEntities.length > 0" class="sub-incidents">
      <div class="incident-container" *ngFor="let subIncident of incident.subIncidentEntities">
        <div class="incident-content">
          <h4>{{ subIncident.subIncidentCreatedTime }} - {{ subIncident.subIncidentTitle }}</h4>
          <div *ngIf="subIncident.subIncidentDescription">{{ subIncident.subIncidentDescription }}</div>
          <div class="mtop" *ngFor="let file of subIncident.subIncidentAttachmentEntities">
            <lseg-aurora-button accent="tertiary" icon="file" (buttonClick)="downloadFile(file)">
              {{ file.fileName }}
            </lseg-aurora-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
