import { adminMenu, userMenu } from 'src/environments/menu';
import { EnvConfig } from '../env-config';

const rootDomain = 'dev2-liveservice.lseg.com';
const applicationDomain = `${rootDomain}/api`;

export const environment: EnvConfig = {
  env: 'dev',
  api: {
    wam: `https://${applicationDomain}/wam/api`,
    dynamo: `https://${applicationDomain}`,
    peoples: `https://${applicationDomain}/peoples/api`,
    fileBucket: `https://${rootDomain}/files/`,
  },
  msalConfig: {
    uri: [
      `https://${applicationDomain}/incident/sub/file/upload`,
      `https://${applicationDomain}/incident/sub/upload/status`,
      `https://${applicationDomain}/incident/create`,
      `https://${applicationDomain}/incident/sub/create`,
      `https://${applicationDomain}/market/create`,
      `https://${applicationDomain}/template/create`,
      `https://${applicationDomain}/*/update`,
      `https://${applicationDomain}/*/delete`,
      `https://${applicationDomain}/*/delete/*`,
      `https://${applicationDomain}/incident/archive/*`,
      `https://${applicationDomain}/market/get/*`,
      `https://${applicationDomain}/template/market/*`,
      `https://${applicationDomain}/template/get/*`,
      `https://${applicationDomain}/template/active`,
      `https://${applicationDomain}/incident/active`,
      `https://${applicationDomain}/wam/api/activities`,
      `https://${applicationDomain}/wam/api/save`,
      `https://${applicationDomain}/wam/api/update`,
      `https://${applicationDomain}/wam/api/get/activity/*`,
      `https://${applicationDomain}/wam/api/delete/*`,
      `https://${applicationDomain}/account/cancel?sso=true*`,
      `https://${applicationDomain}/peoples/api/all/users`,
      `https://${applicationDomain}/peoples/api/update/user/status`,
      `https://${applicationDomain}/peoples/api/get/user?isaduser=true*`,
      `https://${applicationDomain}/peoples/api/get/user?isadmin=true*`,
      `https://${applicationDomain}/peoples/api/user/profile?isaduser=true*`,
      `https://${applicationDomain}/confirm/account/cancel?admin=true*`,
    ],
    scopes: ['api://adda97b5-61fc-4227-97b9-ad6b9657c9d6/user.access', 'api://adda97b5-61fc-4227-97b9-ad6b9657c9d6/admin.access'],
    clientId: 'adda97b5-61fc-4227-97b9-ad6b9657c9d6',
    authority: 'https://login.microsoftonline.com/287e9f0e-91ec-4cf0-b7a4-c63898072181',
    redirectUrl: '/',
  },
  oktaInfo: {
    production: false,
    oktaConfig: {
      clientId: '0oa252qn4xoRYrt0C0h8',
      issuer: 'https://dev-identity.lseg.com/oauth2/aus25ajkapzS9u6Ee0h8',
      redirectUri: window.location.origin + '/callback',
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
      profileUrl: '/peoples/api/user',
      deleteUrl: 'confirm/account/cancel',
      cancelUrl: '/account/cancel',
      viewUserProfile: '/peoples/api/get/user',
    },
  },
  userMenu,
  adminMenu,
};
