import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PeopleFilterableRequest, PeopleRequest } from 'src/app/interfaces/reducer.interface';
import { PeopleResponse, ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { UserEntity } from '../../user/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminPeopleApiService {
  constructor(private http: HttpClient) {}

  allPeople(request: PeopleFilterableRequest): Observable<ResponseEntity<PeopleResponse>> {
    return this.http.post<ResponseEntity<PeopleResponse>>(`${environment.api.peoples}/all/users`, request);
  }

  updateStatus(request: PeopleRequest): Observable<ResponseEntity<UserEntity[]>> {
    return this.http.put<ResponseEntity<UserEntity[]>>(`${environment.api.peoples}/update/user/status`, request);
  }

  getUser(email: string): Observable<ResponseEntity<UserEntity>> {
    return this.http.get<ResponseEntity<UserEntity>>(`${environment.api.peoples}/get/user?isadmin=true&email=${email}`);
  }
}
