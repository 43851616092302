import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavTab } from '@issuerservices/design-system';
import { Observable } from 'rxjs';
import { ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { UpdatedUserEntity, UserEntity } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  public readonly tabs: NavTab[] = [
    { name: 'Log in', path: '/user/login' },
    { name: 'Register', path: '/user/register' },
    // { name: 'Reset password', path: '/user/password' },
  ];

  constructor(private http: HttpClient) {}

  createUser(user: UserEntity): Observable<ResponseEntity<UserEntity>> {
    return this.http.post<ResponseEntity<UserEntity>>(`${environment.api.peoples}/create/user`, user);
  }

  updateUserProfile(user: UpdatedUserEntity, isLoggedInUserOkta: boolean): Observable<ResponseEntity<UserEntity>> {
    const url = isLoggedInUserOkta ? '/user/profile' : '/user/profile?isaduser=true';
    return this.http.put<ResponseEntity<UserEntity>>(`${environment.api.peoples}${url}`, user);
  }

  getUserProfile(email: string, isLoggedInUserOkta: boolean): Observable<ResponseEntity<UserEntity>> {
    const url = isLoggedInUserOkta ? '/get/user' : '/get/user?isaduser=true';
    return this.http.get<ResponseEntity<UserEntity>>(`${environment.api.peoples}${url}`, {
      params: new HttpParams().set('email', email),
    });
  }

  sendCancelUserRequest(email: string, isLoggedInUserOkta: boolean): Observable<ResponseEntity<UserEntity>> {
    let url = new URL(`${environment.api.peoples}/account/cancel`);
    if (!isLoggedInUserOkta) {
      url.searchParams.append('sso', 'true');
    }
    url.searchParams.append('email', email);
    return this.http.delete<ResponseEntity<UserEntity>>(url.toString());
  }

  deleteUserAccount(token: string, email: string): Observable<ResponseEntity<UserEntity>> {
    if (!token) {
      return this.http.delete<ResponseEntity<UserEntity>>(`${environment.api.peoples}/confirm/account/cancel?admin=true&email=${email}`);
    }
    let headers = new HttpHeaders({ Authorization: 'Bearer ' + token, email: email });
    return this.http.delete<ResponseEntity<UserEntity>>(`${environment.api.peoples}/confirm/account/cancel?email=${email}`, { headers });
  }
}
