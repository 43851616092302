import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AppLayoutbModule, LSEG_CONFIG } from '@issuerservices/design-system';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { storeConfig } from './app/store/configuration/rutime-checks';
import { AdminEffects } from './app/store/effects/admin.effects';
import { AppEffects } from './app/store/effects/app.effects';
import { ROOT_REDUCERS, metaReducers } from './app/store/reducers';
import { AuthTokenInterceptor, serverErrorInterceptor } from './app/utils/interceptors';
import { oktaConfig } from './auth.config';
import { environment } from './environments/environment';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, BrowserAnimationsModule, AppRoutingModule, AppLayoutbModule, OktaAuthModule, CommonModule),
    provideStore(ROOT_REDUCERS, {
      runtimeChecks: storeConfig,
      metaReducers,
    }),
    provideEffects([AppEffects, AdminEffects]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideHttpClient(withInterceptors([serverErrorInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: LSEG_CONFIG,
      useValue: {
        layout: 'common',
        showLoggedIn: true,
        isLoggedIn: false,
        loginLink: { title: 'Log in / Register', icon: 'user', link: '/user/login' },
        logoutLink: { title: 'Profile', icon: 'user' },
        logoutMenu: [
          {
            title: 'Sign out',
            link: '/user/logout',
          },
          {
            title: 'My account',
            link: '/user/userAccountProfile',
          },
        ],
        navigation: environment.userMenu,
        showFooter: true,
      },
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: OKTA_CONFIG, useValue: oktaConfig },
  ],
}).catch((err) => console.error(err));

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.authority,
      redirectUri: environment.msalConfig.redirectUrl,
      postLogoutRedirectUri: environment.msalConfig.redirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  environment.msalConfig.uri.forEach((el) => protectedResourceMap.set(el, environment.msalConfig.scopes));

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.msalConfig.scopes],
    },
    loginFailedRoute: '/',
  };
}
