import { DatePipe, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { AuroraButtonModule, AuroraTableModule } from '@issuerservices/design-system';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IncidentRequest } from 'src/app/interfaces/reducer.interface';
import { Incident } from 'src/app/interfaces/response.interface';

@UntilDestroy()
@Component({
  selector: 'lsp-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss'],
  standalone: true,
  imports: [AuroraTableModule, MatTableModule, DatePipe, TitleCasePipe, NgIf, MatProgressSpinnerModule, RouterLink, AuroraButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentsComponent implements OnChanges {
  @Input() data: Incident[];
  @Input() isAdmin = false;

  @Output() edit = new EventEmitter<IncidentRequest>();
  @Output() remove = new EventEmitter<IncidentRequest>();
  @Output() archive = new EventEmitter<IncidentRequest>();

  tableColumns = ['id', 'title', 'market', 'created', 'subincidents'];
  adminTableColumns = ['id', 'status', 'published', 'title', 'market', 'created', 'publishedon', 'subincidents', 'operations'];
  tableData: MatTableDataSource<Incident> = new MatTableDataSource<Incident>([]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.tableData.data = changes['data'].currentValue;
    }
  }

  editItem(id: string, market: string): void {
    this.edit.emit({ id, market });
  }

  removeItem(id: string, market: string): void {
    this.remove.emit({ id, market });
  }

  archiveItem(id: string, market: string): void {
    this.archive.emit({ id, market });
  }
}
