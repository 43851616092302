import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { AuroraButtonModule } from '@issuerservices/design-system';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { subIncidentAttachment } from 'src/app/interfaces/shared.interface';
import { downloadFile, systemStatusDetails } from 'src/app/store/actions/app.actions';
import { State } from 'src/app/store/reducers/app.reducer';
import { selectSelectedIncident } from 'src/app/store/selectors/app.selectors';
import { IncidentResponse } from '../../interfaces/status.interface';

@Component({
  selector: 'lsp-status-details',
  templateUrl: './status-details.component.html',
  styleUrls: ['./status-details.component.scss'],
  imports: [NgIf, NgFor, AsyncPipe, MatProgressSpinnerModule, AuroraButtonModule],
  standalone: true,
})
export class StatusDetailsComponent implements OnInit, OnDestroy {
  timerId: ReturnType<typeof setTimeout>;
  incident$: Observable<IncidentResponse> = this._store.select(selectSelectedIncident);

  constructor(
    private readonly _store: Store<State>,
    private readonly _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dataLoad();
    this.runDetailsRefresh();
  }

  ngOnDestroy(): void {
    if(this.timerId){
      clearInterval(this.timerId);
    }
  }

  dataLoad(): void {
    const params = this._route.snapshot.params;
    this._store.dispatch(systemStatusDetails({ id: params['id'], market: params['market'] }));
  }

  runDetailsRefresh(): void {
    this.timerId = setInterval(()=> this.dataLoad(),30000)
  }

  downloadFile(file: subIncidentAttachment): void {
    this._store.dispatch(downloadFile({ file }));
  }
}
