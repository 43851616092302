import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationAlertModule } from '@issuerservices/design-system';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { deleteUserAccount } from 'src/app/store/actions/app.actions';
import { State } from 'src/app/store/reducers/app.reducer';
import { selectDeleteUserProfileRequestSuccess, selectDeletingUserProfile, selectIsLoggedIn } from 'src/app/store/selectors/app.selectors';

@UntilDestroy()
@Component({
  selector: 'lsp-user-account-delete',
  templateUrl: './user-account-delete.component.html',
  styleUrls: ['./user-account-delete.component.scss'],
  standalone: true,
  imports: [NotificationAlertModule, NgIf, AsyncPipe, MatProgressSpinnerModule],
})
export class UserAccountDeleteComponent implements OnInit {
  isAuthenticated$ = this._store.select(selectIsLoggedIn);
  deletingUserProfile$ = this._store.select(selectDeletingUserProfile);
  deleteUserProfileRequestSuccess$ = this._store.select(selectDeleteUserProfileRequestSuccess);

  token: string;
  email: string;
  dialogRef: MatDialogRef<ModalComponent>;
  constructor(
    private readonly _store: Store<State>,
    private readonly route: ActivatedRoute,
    private readonly _matDialog: MatDialog,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.isAuthenticated$.pipe(untilDestroyed(this)).subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.checkQueryParams();
        this.deleteAccount();
      }
    });
  }

  checkQueryParams(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.token = params['token'];
      this.email = params['email'];
    });
  }

  deleteAccount(): void {
    this.dialogRef = this._matDialog.open(ModalComponent, {
      data: {
        content: `Are you sure you want to delete this account? <p>Deleting your account will permanently remove your profile, data, and settings. This action cannot be undone.</p`,
        contentSize: 2,
        showSpinner: false,
        header: 'Delete Account',
        confirm: 'Delete Account',
        icon: 'circle-xmark',
        iconStyle: 'error',
      },
      panelClass: ['sparklive-dialog-container', 'full-screen'],
      disableClose: true,
    });
    this.dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data?.confirm) {
          this._store.dispatch(deleteUserAccount({ email: this.email, token: this.token }));
        } else {
          this._router.navigate(['admin/people'])
        }
      });
  }
}
