import { createReducer, on } from '@ngrx/store';
import { IncidentFilter, PeopleFilter } from 'src/app/interfaces/reducer.interface';
import { Incident } from 'src/app/interfaces/response.interface';
import { IncidentEntity } from 'src/app/modules/admin/interfaces/incident.interface';
import { MarketResponse } from 'src/app/modules/admin/interfaces/market.interface';
import { MarketTemplate, TemplateEntity } from 'src/app/modules/admin/interfaces/template.interface';
import { UserEntity } from 'src/app/modules/user/interfaces/user.interface';
import { WamEntity } from 'src/app/modules/wam/interfaces/wam.interface';
import {
  addIncidentsRedirect,
  addMarketRedirect,
  addTemplateRedirect,
  addUserRedirect,
  addWamRedirect,
  adminIncident,
  adminIncidentFailure,
  adminIncidentSuccess,
  adminIncidentsFilter,
  adminIncidentsSuccess,
  adminIncidentsTemplates,
  adminIncidentsTemplatesSuccess,
  adminMarket,
  adminMarketFailure,
  adminMarketSuccess,
  adminMarketsSuccess,
  adminPeopleFilter,
  adminPeopleSuccess,
  adminTemplate,
  adminTemplateFailure,
  adminTemplateSuccess,
  adminTemplatesSuccess,
  adminWam,
  adminWamFailure,
  adminWamSuccess,
  adminWamsSuccess,
  archiveIncidentsSuccess,
  cancelMarketRedirect,
  cancelTemplateRedirect,
  cancelWamRedirect,
  getUserSuccess,
  initAdminTemplate,
  initAdminWam,
  removeIncidentsSuccess,
  removeMarket,
  removeMarketFailure,
  removeMarketSuccess,
  removeTemplate,
  removeTemplateFailure,
  removeTemplateSuccess,
  removeWam,
  removeWamFailure,
  removeWamSuccess,
  saveIncidents,
  saveIncidentsFailure,
  saveIncidentsSuccess,
  saveMarket,
  saveMarketFailure,
  saveMarketSuccess,
  saveTemplate,
  saveTemplateFailure,
  saveTemplateSuccess,
  saveWam,
  saveWamFailure,
  saveWamSuccess,
  updateIncidents,
  updateIncidentsFailure,
  updateIncidentsRedirect,
  updateIncidentsSuccess,
  updateMarket,
  updateMarketFailure,
  updateMarketRedirect,
  updateMarketSuccess,
  updateTemplate,
  updateTemplateFailure,
  updateTemplateRedirect,
  updateTemplateSuccess,
  updateUserRedirect,
  updateWam,
  updateWamFailure,
  updateWamRedirect,
  updateWamSuccess,
} from '../actions/admin.actions';

export const adminFeatureKey = 'admin';
export const defaultPageSize = 25;

export interface State {
  marketProcessing: boolean;
  selectedMarket: MarketResponse;

  incidentProcessing: boolean;
  selectedIncident: IncidentEntity;
  adminIncidents: Incident[];
  adminMarkets: MarketResponse[];
  adminMarketTemplates: MarketTemplate[];
  incidentsPageSize: number;
  incidentsPage: [number, number];
  incidentsTotal: number;
  incidentsKey: [string, string];
  incidentsKeyIndex: [number, number];
  adminIncidentFilter: IncidentFilter;
  incidentsFilterChanged: boolean;

  templateProcessing: boolean;
  selectedTemplate: TemplateEntity;
  adminTemplates: TemplateEntity[];
  templatesPageSize: number;
  templatesPage: [number, number];
  templatesTotal: number;
  templatesKey: [string, string];
  templatesKeyIndex: [number, number];

  people: UserEntity[] | null;
  peopleTotal: number;
  peoplePageSize: number;
  peoplePage: [number, number];
  peopleKey: [string, string];
  peopleKeyIndex: [number, number];
  peopleFilterChanged: boolean;
  peopleFilter: PeopleFilter;
  selectedUser: UserEntity;

  wamProcessing: boolean;
  selectedWam: WamEntity;
  wams: WamEntity[];
}

export const initialState: State = {
  marketProcessing: false,
  selectedMarket: null,

  incidentProcessing: false,
  selectedIncident: null,
  adminIncidents: null,
  adminMarkets: [],
  adminMarketTemplates: [],
  incidentsPageSize: defaultPageSize,
  incidentsPage: [1, 1],
  incidentsTotal: -1,
  incidentsKey: [null, null],
  incidentsKeyIndex: [0, 0],
  adminIncidentFilter: null,
  incidentsFilterChanged: false,

  templateProcessing: false,
  selectedTemplate: null,
  adminTemplates: null,
  templatesPageSize: defaultPageSize,
  templatesPage: [1, 1],
  templatesTotal: -1,
  templatesKey: [null, null],
  templatesKeyIndex: [0, 0],

  people: null,
  peopleTotal: -1,
  peoplePageSize: defaultPageSize,
  peoplePage: [1, 1],
  peopleKey: [null, null],
  peopleKeyIndex: [0, 0],
  peopleFilterChanged: false,
  peopleFilter: null,
  selectedUser: null,

  wamProcessing: false,
  selectedWam: null,
  wams: null,
};

export const reducer = createReducer(
  initialState,
  on(updateMarket, saveMarket, adminMarket, removeMarket, (state): State => {
    return { ...state, marketProcessing: true };
  }),
  on(
    removeMarketSuccess,
    updateMarketSuccess,
    saveMarketSuccess,
    adminMarketSuccess,
    removeMarketFailure,
    updateMarketFailure,
    saveMarketFailure,
    adminMarketFailure,
    (state): State => {
      return { ...state, marketProcessing: false };
    }
  ),
  on(updateMarketRedirect, addMarketRedirect, cancelMarketRedirect, adminMarket, (state): State => {
    return { ...state, selectedMarket: null };
  }),
  on(adminMarketSuccess, (state, action): State => {
    return { ...state, selectedMarket: action.response };
  }),

  on(updateIncidentsRedirect, addIncidentsRedirect, (state): State => {
    return { ...state, selectedIncident: null };
  }),
  on(updateIncidents, saveIncidents, adminIncident, (state, action): State => {
    return { ...state, incidentProcessing: true };
  }),
  on(
    updateIncidentsSuccess,
    saveIncidentsSuccess,
    updateIncidentsFailure,
    saveIncidentsFailure,
    adminIncidentSuccess,
    adminIncidentFailure,
    (state): State => {
      return { ...state, incidentProcessing: false };
    }
  ),
  on(adminIncidentsSuccess, (state, action): State => {
    const wasReload = state.incidentsKey[1] !== null && state.incidentsKey[1] === action.response.pagination.nextLastEvaluatedKey;
    return {
      ...state,
      adminIncidents: action.response.incidents,
      incidentsTotal: action.response.pagination.totalRecords,
      incidentsPageSize: action.response.pagination.pageSize,
      incidentsPage: wasReload ? state.incidentsPage : [state.incidentsPage[1], action.response.pagination.currentPage],
      incidentsKey: wasReload ? state.incidentsKey : [state.incidentsKey[1], action.response.pagination.nextLastEvaluatedKey],
      incidentsKeyIndex: wasReload
        ? state.incidentsKeyIndex
        : [state.incidentsKeyIndex[1], action.response.pagination.nextLastEvaluatedIndex],
      incidentsFilterChanged: false,
    };
  }),
  on(adminPeopleSuccess, (state, action): State => {
    const wasReload = state.peopleKey[1] !== null && state.peopleKey[1] === action.response.pagination.nextLastEvaluatedKey;
    return {
      ...state,
      people: action.response.users,
      peopleTotal: action.response.pagination.totalRecords,
      peoplePageSize: action.response.pagination.pageSize,
      peoplePage: wasReload ? state.peoplePage : [state.peoplePage[1], action.response.pagination.currentPage],
      peopleKey: wasReload ? state.peopleKey : [state.peopleKey[1], action.response.pagination.nextLastEvaluatedKey],
      peopleKeyIndex: wasReload ? state.peopleKeyIndex : [state.peopleKeyIndex[1], action.response.pagination.nextLastEvaluatedIndex],
      peopleFilterChanged: false,
    };
  }),
  on(adminPeopleFilter, (state, action): State => {
    return { ...state, peopleFilter: action.filter, peopleFilterChanged: true };
  }),
  on(adminIncidentsFilter, (state, action): State => {
    return { ...state, adminIncidentFilter: action.filter, incidentsFilterChanged: true };
  }),
  on(adminMarketsSuccess, (state, action): State => {
    return {
      ...state,
      adminMarkets: [...action.response],
    };
  }),
  on(adminIncidentsTemplates, (state): State => {
    return {
      ...state,
      adminMarketTemplates: [],
    };
  }),
  on(adminIncidentsTemplatesSuccess, (state, action): State => {
    return {
      ...state,
      adminMarketTemplates: action.response,
    };
  }),
  on(archiveIncidentsSuccess, removeIncidentsSuccess, (state, action): State => {
    return {
      ...state,
      adminIncidents: state.adminIncidents.filter((el) => el.incidentId !== action.response.incidentId),
    };
  }),
  on(adminIncidentSuccess, (state, action): State => {
    return {
      ...state,
      selectedIncident: action.response,
    };
  }),
  on(getUserSuccess, (state, action): State => {
    return {
      ...state,
      selectedUser: action.response,
    };
  }),

  on(updateUserRedirect, addUserRedirect, (state): State => {
    return { ...state, selectedUser: null };
  }),
  on(updateTemplateRedirect, addTemplateRedirect, cancelTemplateRedirect, initAdminTemplate, (state): State => {
    return { ...state, selectedTemplate: null };
  }),
  on(updateTemplate, saveTemplate, adminTemplate, removeTemplate, (state): State => {
    return { ...state, templateProcessing: true };
  }),
  on(
    removeTemplateSuccess,
    updateTemplateSuccess,
    saveTemplateSuccess,
    adminTemplateSuccess,
    removeTemplateFailure,
    updateTemplateFailure,
    saveTemplateFailure,
    adminTemplateFailure,
    (state): State => {
      return { ...state, templateProcessing: false };
    }
  ),
  on(adminTemplatesSuccess, (state, action): State => {
    const wasReload = state.templatesKey[1] !== null && state.templatesKey[1] === action.response.pagination.nextLastEvaluatedKey;
    return {
      ...state,
      adminTemplates: action.response.templates,
      templatesTotal: action.response.pagination.totalRecords,
      templatesPageSize: action.response.pagination.pageSize,
      templatesPage: wasReload ? state.templatesPage : [state.templatesPage[1], action.response.pagination.currentPage],
      templatesKey: wasReload ? state.templatesKey : [state.templatesKey[1], action.response.pagination.nextLastEvaluatedKey],
      templatesKeyIndex: wasReload
        ? state.templatesKeyIndex
        : [state.templatesKeyIndex[1], action.response.pagination.nextLastEvaluatedIndex],
    };
  }),
  on(adminTemplateSuccess, (state, action): State => {
    return {
      ...state,
      selectedTemplate: action.response,
    };
  }),
  on(updateWamRedirect, addWamRedirect, cancelWamRedirect, initAdminWam, (state): State => {
    return { ...state, selectedWam: null };
  }),
  on(updateWam, saveWam, adminWam, removeWam, (state): State => {
    return { ...state, wamProcessing: true };
  }),
  on(
    removeWamSuccess,
    updateWamSuccess,
    saveWamSuccess,
    adminWamSuccess,
    removeWamFailure,
    updateWamFailure,
    saveWamFailure,
    adminWamFailure,
    (state): State => {
      return { ...state, wamProcessing: false };
    }
  ),
  on(adminWamsSuccess, (state, action): State => {
    return {
      ...state,
      wams: action.response,
    };
  }),
  on(adminWamSuccess, (state, action): State => {
    return {
      ...state,
      selectedWam: action.response,
    };
  })
);
